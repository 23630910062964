'use client';

import cookieToMap from '@haaretz/s-cookie-utils/cookieToMap';
import useOnce from '@haaretz/s-use-once';
import React from 'react';

import type { HtzUser, PPMessageProps } from '@haaretz/s-types';

export type User = HtzUser & { isLoggedIn: boolean };

export type Cookie = {
  ppResponseMessage?: PPMessageProps;
  OptanonConsent?: string;
  _htzwif?: string;
  acl?: string;
  userDetails?: Omit<HtzUser, 'anonymousId'>;
  user_details?: string;
  lbcounter?: string;
};

const CookieContext = React.createContext<Cookie | undefined>(undefined);
const CookieSetter = React.createContext<((value?: string) => void) | undefined>(undefined);

function cookieDispenserFactory(cookie: string) {
  const cookieMap = cookieToMap(cookie, true);
  const { ppResponseMessage, userDetails, user_details, acl, _htzwif, OptanonConsent, lbcounter } =
    cookieMap;

  return {
    ppResponseMessage,
    userDetails,
    user_details,
    acl,
    _htzwif,
    OptanonConsent,
    lbcounter,
  } as Cookie;
}

export function CookieProvider({ children }: React.PropsWithChildren) {
  const [cookie, setCookie] = React.useState<Cookie>(
    cookieDispenserFactory(
      typeof window === 'undefined' ? /* istanbul ignore next */ '' : document.cookie
    )
  );

  const cookieSetter = React.useCallback((action?: string) => {
    setCookie(
      cookieDispenserFactory(
        typeof window === 'undefined' ? /* istanbul ignore next */ action ?? '' : document.cookie
      )
    );
  }, []);

  React.useEffect(() => {
    cookieSetter();
  }, [cookieSetter]);

  return (
    <CookieContext.Provider value={cookie}>
      <CookieSetter.Provider value={cookieSetter}>{children}</CookieSetter.Provider>
    </CookieContext.Provider>
  );
}
type StartFrom = 'serverValue' | 'cookieValue';

/**
 *
 * @param startFrom - Determines the initial source of the cookie values:
 *   * `serverValue`: Use this when the cookie values affect the initial render of a component to prevent hydration mismatches.
 *   * `cookieValue`: Use this when the cookie values are only needed after an effect or inside an event handler.
 *
 * @returns The current cookie values.
 * @throws An error if used outside of a `CookieProvider`.
 */
export default function useCookie(startFrom: StartFrom) {
  const context = React.useContext(CookieContext);

  const [updatedStartFrom, setUpdatedStartFrom] = React.useState(startFrom);

  useOnce(() => {
    setUpdatedStartFrom('cookieValue');
  }, startFrom === 'serverValue');

  if (context === undefined) {
    throw new Error('useCookie must be used within a CookieProvider');
  }

  if (updatedStartFrom === 'serverValue') {
    return {};
  }

  return context;
}

export function useSetCookie() {
  const context = React.useContext(CookieSetter);

  if (context === undefined) {
    throw new Error('useSetCookie must be used within a CookieProvider');
  }

  return context;
}

/**
 * @param startFrom - Determines the initial source of the cookie values:
 *   * `serverValue`: Use this when the cookie values affect the initial render of a component to prevent hydration mismatches.
 *   * `cookieValue`: Use this when the cookie values are only needed after an effect or inside an event handler.
 * 
 * @returns The current cookie values.
 * @throws An error if used outside of a `CookieProvider`.

 */
export function useCookieAtom(startFrom: StartFrom) {
  return [useCookie(startFrom), useSetCookie()] as const;
}
