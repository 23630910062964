'use client';

import fork from '@haaretz/l-fork.macro';
import useUser from '@haaretz/s-atoms/user';
import getScriptStrategy from '@haaretz/s-common-utils/getScriptStrategy';
import Script from 'next/script';

import type { ScriptProps } from '../../types';
const event = fork({ default: 'HTZ payer', tm: 'TheMarker subscriber', hdc: 'HDC registered' });

export default function OutbrainObctClient({ strategy }: ScriptProps) {
  const user = useUser('cookieValue');

  return (
    <Script
      id="outbrain-obct"
      strategy={getScriptStrategy(strategy)}
      type="text/javascript"
      data-obct="true"
      dangerouslySetInnerHTML={{
        __html:
          user.userType === 'paying'
            ? `window.obApi('track', '${event}')`
            : "/** not a paying user not running  obApi('track', 'HTZ payer') **/",
      }}
    />
  );
}
