'use client';

import config from '@haaretz/l-config';
import useCookie from '@haaretz/s-atoms/cookie';
import useUser from '@haaretz/s-atoms/user';
import React from 'react';

const GROUPS_REGEX = /(?:groups=)((C000\d:[01],?)+)/i;
const flags = ['S', 'P', 'F', 'T']; // [ S-trictly,  P-erformance, F-unctional, T-argeting, ]

const groups: Record<string, unknown> = flags.reduce((obj, f) => ({ ...obj, [f]: '0' }), {}); // Init all flags to '0'

function initGroups(cookieConsent: string) {
  if (cookieConsent != null) {
    const cookieConsentValue = decodeURIComponent(cookieConsent);
    const match = GROUPS_REGEX.exec(cookieConsentValue);
    const userData = match ? match[1] : null;

    userData &&
      userData
        .split(',') // Split to : [ '1:1' , '2:0' , '3:1' , '4:1' ]
        .forEach(item => {
          // item might contain C000, so we remove it
          const finalStr = item.replace('C000', '');

          // flagIndex is the first char in the string minus one
          const flagIndex = parseInt(finalStr.charAt(0)) - 1;
          const flagValue = flags[flagIndex];

          if (flagValue) {
            groups[flagValue] = finalStr.charAt(finalStr.length - 1);
          }
        });
  }
}

export default function CookieConsentClient() {
  const GDPR_URL = `${config.get('sso')}/updateGdpr`;

  const user = useUser('cookieValue');
  const cookie = useCookie('serverValue');

  React.useEffect(() => {
    // Log Consent Data to SSO
    if (user.isLoggedIn && cookie.OptanonConsent) {
      initGroups(cookie.OptanonConsent);
      fetch(GDPR_URL, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(groups),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
