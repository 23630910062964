'use client';

import getScriptStrategy from '@haaretz/s-common-utils/getScriptStrategy';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import Script from 'next/script';

import type { ScriptProps } from '../../types';

export default function KilkayaClient({ strategy }: ScriptProps) {
  const isLoaded = useLoadEvent();

  return isLoaded ? (
    <Script
      type="text/javascript"
      src="https://cl-eu6.k5a.io/668e5a53d83b2f4bdb79db32.js"
      strategy={getScriptStrategy(strategy, 'lazyOnload')}
      id="kilkaya"
    />
  ) : null;
}
