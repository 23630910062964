'use client';
import getScriptStrategy from '@haaretz/s-common-utils/getScriptStrategy';
import Script from 'next/script';
import React, { useEffect } from 'react';

import type { ScriptItemFragment } from '@haaretz/s-fragments/ScriptItem';

export type GAParams = {
  gaId: string;
  dataLayerName?: string;
  useCustomConfig?: boolean;
} & Pick<ScriptItemFragment, 'strategy'>;
declare global {
  interface Window {
    dataLayer?: object[];
  }
}

let currDataLayerName: string | undefined = undefined;

export function GoogleAnalytics(props: GAParams) {
  const { gaId, dataLayerName = 'dataLayer', useCustomConfig = false } = props;
  const gaScriptStrategy = getScriptStrategy(props.strategy);

  if (currDataLayerName === undefined) {
    currDataLayerName = dataLayerName;
  }

  useEffect(() => {
    // performance.mark is being used as a feature use signal. While it is traditionally used for performance
    // benchmarking it is low overhead and thus considered safe to use in production and it is a widely available
    // existing API.
    // The performance measurement will be handled by Chrome Aurora

    performance.mark('mark_feature_usage', {
      detail: {
        feature: 'next-third-parties-ga',
      },
    });
  }, []);

  return (
    <>
      <Script
        id="_next-ga"
        src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
        strategy={gaScriptStrategy}
      />
      <Script
        id="_next-ga-init"
        strategy="beforeInteractive"
        suppressHydrationWarning
        dangerouslySetInnerHTML={{
          __html: `
          window['${dataLayerName}'] = window['${dataLayerName}'] || [];
          window.gtag = function gtag(){window['${dataLayerName}'].push(arguments)};
          window.gtag('js', new Date());

          ${useCustomConfig ? '' : `window.gtag('config', '${gaId}');`}`,
        }}
      />
    </>
  );
}

export function sendGAEvent(..._args: NonNullable<unknown>[]) {
  if (currDataLayerName === undefined) {
    console.warn('@next/third-parties: GA has not been initialized');
    return;
  }

  if (window[currDataLayerName as keyof Window]) {
    // eslint-disable-next-line prefer-rest-params
    window[currDataLayerName as keyof Window].push(arguments);
  } else {
    console.warn(`@next/third-parties: GA dataLayer ${currDataLayerName} does not exist`);
  }
}
