import type { ScriptItemFragment } from '@haaretz/s-fragments/ScriptItem';
import type { ScriptProps } from 'next/script';

export default function getStrategy(
  strategy: ScriptItemFragment['strategy'],
  defaultValue?: ScriptProps['strategy']
) {
  if (strategy === 'worker') {
    return strategy;
  }

  return defaultValue;
}
